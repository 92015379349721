import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';

/* eslint-disable @typescript-eslint/no-explicit-any */
export * as environment from './environment';
export * as localStorage from './localStorage';
export * as http from './http';

export type Report = {
  id?: number;
  registredAt: Date;
};

export const signOutAndDestroySession = () => {
  // more actions are made at signOut() see [...nextauth].js file
  localStorage.removeItem('cachedSession');
  return signOut();
};

export const isRoleAllowed = (roles: string[], validToken: Session | null) => {
  if (!validToken || !roles) return false;

  return roles.some((role: string) => {
    const realm = validToken.user.realm_access.roles.includes(role);
    return realm;
  });
};

const formatCpf = (cpf: string) => {
  const strCpf = `${cpf}`;
  if (strCpf.length > 11) {
    return strCpf.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }
  return strCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const formatMoney = (amount: number) => {
  const intl = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return intl.format(amount);
};

export { formatCpf, formatMoney };

export const isMobileFunc = () => {
  return typeof window !== 'undefined' && window.innerWidth < 768;
};

export const handleTrackingUrl = (
  url: string,
  trackingCode: string | undefined | null,
) => {
  const protocoledUrl: string = (() => {
    if (!url.includes('https://') && !url.includes('http://')) {
      return `https://${url}`;
    }

    return url;
  })();

  if (
    !protocoledUrl ||
    !trackingCode ||
    (trackingCode &&
      protocoledUrl.toLowerCase().includes(trackingCode.toLowerCase()))
  ) {
    return protocoledUrl;
  }

  if (
    protocoledUrl.charAt(protocoledUrl.length - 1) === '/' ||
    protocoledUrl.charAt(protocoledUrl.length - 1) === '='
  ) {
    return `${protocoledUrl}${trackingCode}`;
  }

  return `${protocoledUrl}/${trackingCode}`;
};

export const forceDownload = (
  data: BlobPart,
  filename: any,
  type = 'text/csv',
) => {
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}`);
  document.body.append(link);
  link.click();
};

export const sortReports = (a: Report, b: Report) => {
  const dateA = new Date(a.registredAt).getTime();
  const dateB = new Date(b.registredAt).getTime();
  return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
};

const zeroIfNothing = (value: string | number) => {
  if (value) return value;

  return '0';
};

export default zeroIfNothing;
