import { DataLayerEvent } from './trackingEvents.types';

export const pushDataLayer = (objDataLayer: DataLayerEvent) => {
  if (typeof window === 'undefined') return;

  window.dataLayer = window.dataLayer || [];

  const exists = window.dataLayer.some((item) => {
    return (
      typeof item.event === 'string' &&
      typeof item.page_url === 'string' &&
      typeof item.page_type === 'string' &&
      item.timestamp instanceof Date &&
      objDataLayer.timestamp instanceof Date &&
      item.event === objDataLayer.event &&
      item.page_url === objDataLayer.page_url &&
      item.page_type === objDataLayer.page_type &&
      item.item_id === objDataLayer.item_id &&
      item.timestamp.getTime() === objDataLayer.timestamp.getTime()
    );
  });

  if (exists) return;

  window.dataLayer.push(objDataLayer);
};

const formatItemId = (componentType: string, title: string) => {
  return `${componentType}_${title.toLowerCase().replace(/\s+/g, '_')}`;
};

export const sendEvent = (
  eventName: string,
  eventData: Partial<DataLayerEvent> = {},
) => {
  const objEvent: DataLayerEvent = {
    event: eventName,
    page_url: eventData.page_url || window.location.href,
    page_type: eventData.page_type || '',
    event_type: eventData.event_type,
    component: eventData.component || '',
    micro_component: eventData.micro_component || '',
    link: eventData.link,
    content_type: eventData.content_type,
    item_id:
      eventData.item_id ||
      formatItemId(
        eventData.micro_component || '',
        eventData.link?.anchor_text || '',
      ),
    timestamp: eventData.timestamp || new Date(),
    user_id: eventData.user_id || '',
  };
  pushDataLayer(objEvent);
};

export const sendClickEvent = ({
  component,
  micro_component,
  item_id,
  content_type,
}: Partial<DataLayerEvent>) => {
  sendEvent('select_content', {
    event_type: 'click',
    component,
    micro_component,
    item_id,
    content_type,
    page_url: window.location.href,
    timestamp: new Date(),
  });
};

export const sendPageView = (page_url: string, page_type: string) => {
  sendEvent('pageView', { page_url, page_type });
};

export const sendVideoComplete = (
  embeddedId: string,
  event: { data: number },
  user: string,
) => {
  if (event.data === window.YT.PlayerState.ENDED) {
    sendEvent('video_complete', {
      event_type: 'completion',
      component: 'VideoBox',
      content_type: 'video',
      item_id: embeddedId,
      user_id: user,
    });
  }
};

export const sendModalViewEvent = (title: string) => {
  sendEvent('modal_view', {
    event_type: 'view',
    component: `Modal ${title}`,
    content_type: title,
  });
};
