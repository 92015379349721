import { getJson, postJson } from './httpClient/httpClientBff';

const url = `${process.env.NEXT_PUBLIC_PORTAL_SELLER_WEBAPI}`;

export const getLatestAgreement = async (groupId: string) => {
  try {
    const req = await getJson(`${url}v1/agreements/${groupId}/latest`);

    return req.data.data ? req.data.data : req;
  } catch {
    console.error(`getLastAgreement: Something went wrong`);
    return [];
  }
};

export const postAgreements = async (
  groupId: string,
  agreementId: string,
  documentUrl: string,
  documentType: string,
) => {
  const req = await postJson(`${url}v1/agreements/${groupId}`, {
    agreementId,
    documentUrl,
    documentType,
  });
  return req;
};
