import React, { createContext, ReactNode, useContext } from 'react';

type FeatureFlags = {
  [key: string]: boolean;
};

const UnleashContext = createContext<FeatureFlags>({});

export const UnleashProviderMock = ({ children }: { children: ReactNode }) => {
  const featureFlags = {
    'portal-seller-front.shipping-management': true,
  };

  return (
    <UnleashContext.Provider value={featureFlags}>
      {children}
    </UnleashContext.Provider>
  );
};

export const useMockedFlag = (toggleName: string) => {
  const flags = useContext(UnleashContext);
  return flags[toggleName] ?? false;
};
