/* eslint-disable @typescript-eslint/no-unsafe-argument */
import axios, {
  AxiosRequestConfig,
  AxiosResponseHeaders,
  RawAxiosRequestHeaders,
} from 'axios';
import { format } from 'date-fns';
import { getSession as getSessionNextAuth } from 'next-auth/react';
import { formatErrorResponse } from './utils';

export type RequestHeaders = RawAxiosRequestHeaders & {
  token?: string;
  timeout?: number;
};

export type HttpClientResponse = {
  error: undefined;
  data: Record<string, unknown>;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<unknown>;
  request?: Record<string, unknown>;
};

const MILLISECONDS_THRESHOLD = 1000;

const fetchSession = async () => {
  const response = await getSessionNextAuth();
  localStorage.setItem('cachedSession', JSON.stringify(response));
  return response;
};

export const getSession = async () => {
  try {
    const sessionString = localStorage.getItem('cachedSession');
    const session = sessionString ? JSON.parse(sessionString) : undefined;
    if (
      session &&
      Date.now() < session.accessTokenExpires - MILLISECONDS_THRESHOLD
    ) {
      return session;
    } else {
      throw new Error('token expired or invalid');
    }
  } catch {
    return await fetchSession();
  }
};

export const updateSellerToken = (newToken: string) => {
  try {
    const sessionString = localStorage.getItem('cachedSession');
    const session = sessionString ? JSON.parse(sessionString) : undefined;
    if (session) {
      session.activeGroup.token = newToken;
      localStorage.setItem('cachedSession', JSON.stringify(session));
    }
  } catch {
    throw new Error('Failed to update seller token');
  }
};

const stripBearerToken = (token: string) => {
  return token.replace('Bearer ', '') || '';
};

const getJson = async (
  url: string,
  secondaryHeader?: RequestHeaders,
  withCredentials = false,
  options = {},
) => {
  const session = await getSession();
  const headers = {
    ...secondaryHeader,
    Authorization: `Bearer ${session?.accessToken}`,
    token: stripBearerToken(session?.activeGroup?.token || ''),
  };

  try {
    const response = await axios.get(url, {
      ...options,
      headers,
      withCredentials,
    });
    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }

    return {
      data: {},
      error: error,
      status: '',
      statusText: '',
      headers: {},
      config: {},
    };
  }
};

const postJson = async (
  url: string,
  body: unknown,
  secondaryHeader?: RequestHeaders,
  withCredentials = true,
  options = {},
) => {
  const session = await getSession();
  const headers = {
    ...secondaryHeader,
    token: stripBearerToken(session?.activeGroup?.token || ''),
    Authorization: `Bearer ${session?.accessToken}`,
  };

  try {
    const response = await axios.post(url, body, {
      ...options,
      headers,
      withCredentials,
    });
    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }

    return {
      data: {},
      error: error,
      status: '',
      statusText: '',
      headers: {},
      config: {},
    };
  }
};

const putJson = async (
  url: string,
  body: unknown,
  secondaryHeader?: RequestHeaders,
  withCredentials = false,
  options = {},
) => {
  const session = await getSession();
  const headers = {
    ...secondaryHeader,
    token: stripBearerToken(session?.activeGroup?.token || ''),
    Authorization: `Bearer ${session?.accessToken}`,
  };

  try {
    const response = await axios.put(url, body, {
      ...options,
      headers,
      withCredentials,
    });

    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error('Ocorreu um erro inesperado');
  }
};

export { getJson, postJson, putJson };
