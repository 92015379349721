import { format } from 'date-fns';
import { signIn } from 'next-auth/react';

export type HttpClientError = {
  response?: { status: number; data: Record<string, unknown> };
};

const formatErrorResponse = (err: HttpClientError & Error, url: string) => {
  const isCommonError = err instanceof Error;
  const out = {
    error: {
      axiosMessage: err.toString(),
      status: isCommonError ? err.response?.status : 0,
      data: {},
    },
    data: {},
  };

  if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
    console.error(
      `ERROR ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
      err.message,
    );
  }

  if (isCommonError && err.response) {
    const { status, data } = err.response;

    if (status) out.error.status = status;
    if (data) out.error.data = data;

    if (status === 401) {
      console.error(`API responded with 401 for endpoint ${url}`);

      if (location && typeof location !== 'undefined') signIn('keycloak');
    }
  }

  return out;
};

export { formatErrorResponse };
