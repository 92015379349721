/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable promise/always-return */
/* eslint-disable unicorn/no-null */
// TODO: Need refactor
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Close, SportsHandball } from '@grupo-sbf/motriz-icons/centauro';
import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import {
  ImageBanner,
  StyledCTAButton,
  StyledHeading,
  StyledLeaveButton,
  StyledRoot,
} from './FormNps.styled';

const FormNps = ({
  onClose,
  title,
  content,
  buttonUrl,
  buttonText,
}): ReactElement => {
  const onCloseHandler = (): void => {
    onClose();
  };

  return (
    <StyledRoot>
      <ImageBanner />
      <StyledHeading gutterBottom>{title}</StyledHeading>
      <Typography>{content}</Typography>
      <Typography>
        Tempo para resposta: <strong>1 minuto.</strong>
      </Typography>
      <div>
        <StyledCTAButton href={buttonUrl} target="_blank">
          <SportsHandball />
          <strong>{buttonText}</strong>
        </StyledCTAButton>
      </div>
      <div>
        <StyledLeaveButton onClick={onCloseHandler}>
          <Close />
          <strong>Deixar passar</strong>
        </StyledLeaveButton>
      </div>
    </StyledRoot>
  );
};

export default FormNps;
