import { getJson } from './httpClient/httpClientBff';

const url = `${process.env.NEXT_PUBLIC_PUBLIC_STRAPI_API_URL}`;

const getAnnouncement = async () => {
  try {
    const req = await getJson(
      `${url}/announcements?sort=id%3Adesc&pagination[pageSize]=1`,
    );

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getAnnouncement: Something went wrong`);
    return [];
  }
};

const getNews = async () => {
  try {
    const req = await getJson(`${url}/news?sort=id%3Adesc`);

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getNews: Something went wrong`);
    return [];
  }
};

const getTutorials = async () => {
  try {
    const req = await getJson(`${url}/tutorials?sort=id%3Adesc`);

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getTutorials: Something went wrong`);
    return [];
  }
};

const getNPS = async () => {
  try {
    const req = await getJson(`${url}/nps?sort=id%3Adesc`);

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getNPS: Something went wrong`);
    return [];
  }
};

const getTips = async (pageUrl: string) => {
  try {
    const req = await getJson(
      `${url}/tips?filters%5BpageUrl%5D%5B$eq%5D=${pageUrl}`,
    );

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getTips: Something went wrong`);
    return [];
  }
};

const getHeader = async (pageUrl: string) => {
  try {
    const req = await getJson(
      `${url}/headers?filters%5BpageUrl%5D%5B$eq%5D=${pageUrl}`,
    );

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getHeaders: Something went wrong`);
    return [];
  }
};

export { getAnnouncement, getNews, getTutorials, getNPS, getTips, getHeader };
