import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useState, useEffect } from 'react';
import { checkForKeycloakTokenStatus } from '@/services/keycloakModule';

const useSessionAlive = () => {
  const [isSessionAlive, setIsSessionAlive] = useState<boolean>(false);

  const { data: response } = useQuery({
    queryKey: ['keycloak-token-check'],
    queryFn: checkForKeycloakTokenStatus,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (response && (response as AxiosResponse).status === 200) {
      setIsSessionAlive(true);
    }
  }, [response]);

  return { isSessionAlive };
};

export default useSessionAlive;
