import { KeycloakRoles, KeycloakTokenParsed } from 'keycloak-js';

export const ADMINISTRATOR_ROLE = 'Administrator';

export const SELLER_ADMINISTRATOR_ROLE = 'seller-admin';

export type PrivateRouteProps = {
  roles: string[];
  children: React.ReactNode;
  path?: string;
  exact?: boolean;
};

export type UserInfoType = {
  sub?: string;
  is_admin?: boolean;
  email_verified?: boolean;
  name?: string;
  groups?: string[];
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
  email?: string;
};

export type KeycloakGroupType = {
  id: string;
  name: string;
  nome: string;
  codigo: string;
  path?: string;
  subGroups?: unknown[];
  token: string;
  status?: number;
  error?: AxiosError;
  tipoCentauroEnviosId?: number;
  enabled: boolean;
};

export type KeycloakGroupListType = {
  offset?: number;
  limit?: number;
  items: KeycloakGroupType[];
  hasNextPage?: boolean;
};

export type TokenParsedType = KeycloakTokenParsed & {
  roles?: string[];
  groups?: string[];
};

export type AxiosError = {
  id?: string;
  data?: Record<string, never>;
  error?: { axiosMessage?: string; status?: number } | unknown;
};

export type KeycloakLoginError = {
  id?: string;
  axiosMessage?: string;
  status?: number;
};

export type KeycloakLoginErrorList = KeycloakLoginError[];

export type KeycloakContextType = {
  keycloakUserData?: UserInfoType;
  activeGroup: KeycloakGroupType;
  isAuthenticated: boolean;
  doLogin: () => void;
  doLogout: () => void;
  isAdmin: () => boolean;
  isSellerAdmin: () => boolean;
  keycloakError?: KeycloakLoginError;
  isWaitingForKeycloak: boolean;
  token: string;
  tokenParsed:
    | KeycloakTokenParsed
    | ({ realmAccess: KeycloakRoles } & undefined);
  isRoleAllowed: (roles: string[]) => boolean;
};

export type KeycloakTokenStatus = 'WAITING' | 'NOT_FOUND' | 'OK';

export type KeycloakTokenPromise = {
  msg?: string;
  status?: KeycloakTokenStatus;
};

export type KeycloakResponseReq = KeycloakLoginError & UserInfoType;
