import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { getLatestAgreement } from '@/services/agreementsModule';
import { getAnnouncement, getNews, getNPS } from '@/services/strapiJsModule';
import {
  StrapiAgreements,
  StrapiAgreementsError,
  StrapiAnnouncements,
  StrapiNews,
  StrapiNotices,
  StrapiNPS,
} from '../types/strapi';

export const useStrapiNotices = (): StrapiNotices => {
  const { data: session } = useSession();
  const router = useRouter();
  const currentAccount = session?.activeGroup;
  const { accessToken } = session || {};

  const [announcements, setAnnouncements] = useState<StrapiAnnouncements[]>([]);
  const [newsletters, setNewsletters] = useState<StrapiNews[]>([]);
  const [nps, setNPS] = useState<StrapiNPS[]>([]);
  const [latestAgreement, setLatestAgreement] = useState<
    StrapiAgreements[] | StrapiAgreementsError
  >([]);

  useEffect(() => {
    (async () => {
      try {
        if (accessToken && currentAccount?.id) {
          const announcementsReq: StrapiAnnouncements[] =
            await getAnnouncement();
          const newsReq: StrapiNews[] = await getNews();
          const npsReq: StrapiNPS[] = await getNPS();
          const agreementsReq: StrapiAgreements[] | StrapiAgreementsError =
            await getLatestAgreement(currentAccount.id);

          setAnnouncements(announcementsReq);
          setNewsletters(newsReq);
          setLatestAgreement(agreementsReq);
          setNPS(npsReq);
        }
      } catch (error: unknown) {
        console.error('Failed to execute: useStrapiNotices');
        setAnnouncements([]);
        setNewsletters([]);
        setLatestAgreement(error as StrapiAgreementsError);
      }
    })();
  }, [accessToken, currentAccount, router.asPath]);

  return { announcements, newsletters, latestAgreement, nps };
};
