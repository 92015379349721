import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import type { Pluggable } from 'unified';
import { StyledReactMarkDown } from './Markdown.styled';

export type MarkdownProps = {
  children: string;
};

const Markdown = ({ children }: MarkdownProps) => {
  return (
    <StyledReactMarkDown>
      <ReactMarkdown rehypePlugins={[rehypeRaw as Pluggable]}>
        {children}
      </ReactMarkdown>
    </StyledReactMarkDown>
  );
};

export default Markdown;
