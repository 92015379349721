import {
  Home,
  Bag,
  Book,
  TrendingUp,
  Wallet,
  ChatOn,
  Chart,
  Settings,
} from '@grupo-sbf/motriz-icons/centauro';
import {
  homePath,
  orderListPath,
  catalogPath,
  orderReports,
  productReportsPath,
  financialPath,
  generalFaqPath,
  generateReportsPath,
  completedReportsPath,
  tutorialsPath,
  massiveActionsPath,
  userProfile,
  exchangesPath,
  shippingManagementPath,
} from '@/paths';

export const SELLER_SESSION = {
  changeSeller: 'Mudar de seller',
  logOut: 'Deslogar',
};

export const MENU = [
  {
    icon: Home,
    title: 'Ínicio',
    content: [],
    link: homePath,
  },
  {
    icon: Bag,
    title: 'Pedidos',
    content: [
      { title: 'Lista de pedidos', link: orderListPath, active: false },
      { title: 'Lista de devoluções', link: exchangesPath, active: false },
      {
        title: 'Gestão de envios',
        link: shippingManagementPath,
        active: false,
      },
      { title: 'Ações massivas', link: massiveActionsPath, active: false },
    ],
    link: '',
  },
  {
    icon: Book,
    title: 'Catálogo',
    content: [{ title: 'Lista de Catálogo', link: catalogPath, active: false }],
    link: '',
  },
  {
    icon: TrendingUp,
    title: 'Reputação',
    content: [
      { title: 'Gerar relatórios', link: generateReportsPath, active: false },
    ],
    link: '',
  },
  {
    icon: Chart,
    title: 'Relatórios',
    content: [
      { title: 'Pedidos', link: orderReports, active: false },
      {
        title: 'Catálogo',
        link: productReportsPath,
        active: false,
      },
      {
        title: 'Reputação',
        link: completedReportsPath,
        active: false,
      },
    ],
    link: '',
  },
  {
    icon: ChatOn,
    title: 'Central de ajuda',
    content: [
      { title: 'FAQ', link: generalFaqPath, active: false },
      {
        title: 'ServiceNow',
        link: 'https://sbfprod.service-now.com/',
        active: false,
      },
      { title: 'Tutoriais', link: tutorialsPath, active: false },
    ],
    link: '',
  },
  {
    icon: Wallet,
    title: 'Financeiro',
    content: [
      { title: 'Relatórios de Repasse', link: financialPath, active: false },
    ],
    link: '',
  },
  {
    icon: Settings,
    title: 'Configurações',
    content: [{ title: 'Meu perfil', link: userProfile, active: false }],
    link: '',
  },
];
