/* eslint-disable react-hooks/rules-of-hooks */
import { useFlag } from '@unleash/proxy-client-react';
import { useMockedFlag } from './useUnleash.mock';

const useUnleash = (toggleName: string) => {
  if (toggleName === '') {
    throw new Error('Feature Toggle name passed to useToggle is invalid');
  }

  if (process.env.STORYBOOK === 'true') {
    return useMockedFlag(toggleName);
  }

  return useFlag(toggleName);
};

export default useUnleash;
